.product-help {
  margin-top: 60px;
  padding-bottom: 60px;
  background-color: var(--dClick-white);

  .container {
    width: 82%;
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    p {
      font-size: var(--dClick-font-size-small);
      font-weight: 400;
    }
    h2 {
      font-size: var(--dClick-font-size-large);
      font-weight: 700;
    }
    .product-help-card-list {
      display: flex;
      gap: 20px;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .product-help {
    .container {
      width: 90%;
      p {
        font-size: var(--dClick-font-size-x-small);
        margin: 0;
      }
      h2{
        font-size: var(--dClick-font-size-small);
        margin-top: 0;
      }
      .product-help-card-list {
        flex-direction: column;
      }
    }
  }
  
}