.top-section-layout {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(237, 70, 34, 0.5) 0%,
    #ed4622 0%,
    #f5856d 0%,
    #ed4622 41.4%,
    #ed4622 73.06%,
    #ed4622 107.15%
  );
  svg {
    width: 100%;
    height: 100%;
  }
  .right-ellipse {
    position: absolute;
    right: 0;
  }
  .services-image {
    top: 0;
    height: 100%;
    object-fit: cover;
    img {
      height: 100%;
    }
  }
  .ellipse-2-container {
    top: 160px;
    width: 37%;
  }
  .ellipse-1-container {
    width: 11%;
    margin-bottom: -7px;
  }
  .ellipse-3-container {
    top: 100px;
    width: 34%;
  }
  .ellipse-4-container {
    top: 0px;
    width: 80%;
  }
  .ellipse-5-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 92%;
    margin-bottom: -7px;
  }
  .top-section-layout-custom-content {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 80%;
  }
}
@media only screen and (max-width: 1250px) {
  .top-section-layout {
    height: 560px;
    .services-image {
      top: 0px;

      right: 0;
      object-fit: cover;
      img {
        height: unset;
      }
    }
    .ellipse-1-container {
      width: 25%;
      position: absolute;
      left: -10%;
      bottom: -5%;
    }
    .ellipse-4-container {
      top: 0px;
      width: 170%;
      height: 121%;
      left: 17%;
    }
    .ellipse-2-container {
      bottom: -37%;
      width: 75%;
    }
    .ellipse-3-container {
      bottom: -37%;
      right: -17px;
      width: 75%;
    }
    .top-section-layout-custom-content {
      width: 85%;
    }
  }
}
@media (min-width: 500px) and (max-width: 1250px) {
  .top-section-layout {
    .ellipse-4-container {
      top: 0px;
      width: 130%;
      height: 121%;
      left: -12%;
    }
  }
}
