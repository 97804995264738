.about-us {
  display: flex;
  flex-direction: column;
  background-color: var(--dClick-background-color);
  padding-bottom: 30px;
  .about-us-header {
    width: 100vw;
    gap: 120px;
    margin-top: -500px;
    display: flex;
    .about-us-header-description {
      align-self: flex-start;
      margin-left: 100px;
      font-weight: 500;
      color: var(--dClick-white);
      font-size: var(--dClick-font-size-small-medium);
      h2 {
        font-weight: 700;
        font-size: var(--dClick-font-size-large);
      }
    }
    .about-us-header-image {
      padding-top: 100px;
      object-fit: contain;
      z-index: 4;
      img {
        height: 430px;
        margin-right: 17px;
      }
    }
  }
  .about-us-body {
    background-color: var(--dClick-background-color);
    z-index: 4;
    .about-us-container {
      margin-top: 50px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      .about-us-title {
        span {
          font-weight: 400;
          font-size: var(--dClick-font-size-small);
        }
        h2 {
          font-weight: 700;
          font-size: var(--dClick-font-size-large);
        }
      }
      .about-us-card-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .about-us {
    .about-us-header {
      flex-direction: column;
      gap: 0;
      .about-us-header-description {
        margin-left: 10px;
        font-size: var(--dClick-font-size-x-small);
        width: 78%;
        font-weight: 400;
        h2 {
          font-size: var(--dClick-font-size-small);
        }
      }
      .about-us-header-image {
        padding-top: unset;
        display: grid;
        justify-content: flex-end;
        img {
          height: 144px;
          margin-right: unset;
          justify-self: flex-end;
        }
      }
    }
    .about-us-body {
      .about-us-container {
        width: 90%;
        .about-us-title {
          span {
            font-size: var(--dClick-font-size-x-small);
          }
          h2 {
            font-size: var(--dClick-font-size-medium);
          }
        }
        .about-us-card-list {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
@media (min-width: 425px) and (max-width: 1250px) {
  .about-us {
    .about-us-header {
      .about-us-header-image {
        img {
          height: 330px;
        }
      }
    }
  }
}
