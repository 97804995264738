.clients-section {
  .clients-section-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    .clients-section-title {
      p {
        font-weight: 400;
        font-size: var(--dClick-font-size-small-medium);
      }
      h2 {
        font-weight: 700;
        font-size: var(--dClick-font-size-large);
      }
    }
    .clients-section-images {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      .client {
        height: 200px;
        width: 260px;
        
        background-size: cover;
      }

      .ul {
        background-image: url("../../assets/UL_Mark gray.png");
      }
      .ul:hover {
        background-image: url("../../assets/UL_Mark\ colored.png");
      }
      .jeel {
        background-image: url("../../assets/Jeel\ logo\ gray.png");
      }
      .jeel:hover {
        background-image: url("../../assets/Jeel\ logo\ colored.png");
      }
      .rtgs {
        background-image: url("../../assets/rtgs-Logo-gray.png");
      }
      .rtgs:hover {
        background-image: url("../../assets/rtgs-Logo-colored.png");
      }
      .british-gas {
        background-image: url("../../assets/British-Gas-logo_gray.png");
      }
      .british-gas:hover {
        background-image: url("../../assets/British-Gas-logo-colored.png");
      }
    }
    .clients-section-main-client {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      /*to be removed when the site is complete*/
      display: none;
      /*---------------*/
      .main-client-description {
        flex: 1;
        align-self: center;
        p {
          font-weight: 400;
          color: var(--dClick-sub-text-color);
          font-size: var(--dClick-font-size-small);
        }
        img {
          width: 124px;
        }
      }
      .main-client-image {
        background-color: var(--dClick-color-red-secondary-light);
        width: 793px;
        height: 570px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
          transition: transform 0.5s;
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .clients-section {
    .clients-section-container {
      width: 90%;
      .clients-section-title {
        p {
          font-size: var(--dClick-font-size-x-small);
        }
        h2 {
          font-size: var(--dClick-font-size-small);
        }
      }
      .clients-section-images {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .client {
          height: 162px;
          width: 217px;
          background-size: contain;
        }
      }
      .clients-section-main-client {
        flex-direction: column;

        .main-client-description {
          p {
            font-size: var(--dClick-font-size-x-small);
          }
        }
        .main-client-image {
          width: unset;
          height: unset;
          margin-top: 20px;
        }
      }
    }
  }
}
