.button {
  width: 150px;
  height: 44px;

  background: var(--dClick-color-red-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  color: var(--dClick-white);
  cursor: pointer;
  transition: all 0.5s ease-out;
  .button-arrow {
    font-size: var(--dClick-font-size-medium);
    transition: all 0.5s ease-out;
    opacity: 0;
    margin-left: 110px;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    background: var(--dClick-color-red-normal-hover);
    padding-right: 40px;
    .button-arrow {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
