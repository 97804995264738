.contact-section {
  background-color: var(--dClick-white);
  padding-bottom: 60px;
  .contact-section-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    gap: 20px;
    .contact-form {
      width: 50%;
      .contact-form-title {
        font-weight: 700;
        font-size: var(--dClick-font-size-x-large);
      }
      .contact-form-description {
        font-weight: 400;
        font-size: var(--dClick-font-size-small);
        color: var(--dClick-sub-text-color);
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    .contact-image {
      background-color: var(--dClick-color-primary-third-blue);
      img {
        width: 488px;
        height: 470px;
        object-fit: cover;
      }
      .contact-image-info {
        height: 70px;
        color: var(--dClick-white);
        margin-left: 15px;
        .contact-image-title {
          font-weight: 700;
          font-size: var(--dClick-font-size-small-medium);
          margin-bottom: 0;
        }
        .contact-image-description {
          font-weight: 500;
          font-size: var(--dClick-font-size-small);
          margin-top: 4px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .contact-section {
    .contact-section-container {
      width: 90%;
      flex-direction: column-reverse;
      .contact-form {
        width: 90%;
        .contact-form-title {
          font-size: var(--dClick-font-size-small);
        }
        .contact-form-description {
          font-size: var(--dClick-font-size-x-small);
        }
      }
      .contact-image {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
