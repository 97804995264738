.right-arrow {
  width: 15px;
  cursor: pointer;
  position: relative;
  .arrow {
    width: 100%;
    height: 2px;
  }
  .arrow::after,
  .arrow::before {
    content: "";
    position: absolute;
    width: 60%;
    height: 2px;
    right: -3px;
    background-color: inherit;
  }

  .arrow::after {
    top: -3px;
    transform: rotate(45deg);
  }

  .arrow::before {
    top: 3px;
    transform: rotate(-45deg);
  }
}
