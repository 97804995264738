.checkout-services-card {
  background-color: var(--dClick-color-red-normal);
  color: var(--dClick-white);
  font-weight: 500;
  padding: 20px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 330px;
  .checkout-card-title {
    font-size: var(--dClick-font-size-medium);
    font-weight: 500;
  }
  .checkout-services-card-link {
    width: 100px;
    a {
      color: var(--dClick-white);
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    font-size: var(--dClick-font-size-small);
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 40px;
    transition: all 0.5s ease;
    height: 24px;
    span {
      position: relative;
      top: 10%;
      font-size: var(--dClick-font-size-small-medium);
    }
  }
  &:hover {
    .checkout-services-card-link {
      right: 20px;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .checkout-services-card {
    min-height: 240px;
    width: unset;
  }
}
