.services {
  background-color: var(--dClick-background-color);
  padding-bottom: 50px;
  .services-header {
    color: var(--dClick-white);
    font-weight: 700;
    font-size: var(--dClick-font-size-large);
    text-align: center;
    .services-header-body {
      font-weight: 500;
      font-size: var(--dClick-font-size-small-medium);
    }
  }
  .services-body {
    .services-body-container {
      margin-top: -100px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      column-gap: 50px;
      row-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .services {
    .services-header {
      font-size: var(--dClick-font-size-medium);
      .services-header-body {
        font-size: var(--dClick-font-size-small);
      }
    }
    .services-body {
      .services-body-container {
        margin-top: -10px;
        width: 90%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
