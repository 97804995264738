.identity-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  font-weight: 400;
  background-color: var(--dClick-white);
  padding: 40px;
  font-size: var(--dClick-font-size-small);
  h2 {
    font-size: var(--dClick-font-size-medium);
    font-weight: 700;
  }
  .identity-card-description {
    color: var(--dClick-sub-text-color);
  }
  .identity-card-link {
    a {
      color: var(--dClick-color-primary-third-blue);
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    font-size: var(--dClick-font-size-small-medium);
    cursor: pointer;
    margin-top: 30px;
    span {
      transition: all 0.5s ease;
    }
    &:hover {
      span {
        padding-left: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .identity-card {
    display: flex;
    flex-direction: column-reverse;
    font-size: var(--dClick-font-size-x-x-small);
    padding: 10px;
    h2 {
      font-size: var(--dClick-font-size-small);
      font-weight: 700;
    }
    .identity-card-description {
      font-size: var(--dClick-font-size-x-small);
    }
    .identity-card-link {
      font-size: var(--dClick-font-size-small);

      a {
        display: flex;
        align-items: center;
      }
    }
    .identity-card-image {
      img {
        width: 100%;
      }
    }
  }
}
