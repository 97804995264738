.download-app {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .download-app-error {
    color: red;
  }
}
