.technology-card {
  font-size: var(--dClick-font-size-small-medium);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 30px 30px;
  border-bottom: 0.5px solid rgba(102, 102, 103, 0.44);
  border-right: 0.5px solid rgba(102, 102, 103, 0.44);
  transition: all 0.5s ease;
  text-align: center;
  &:nth-child(4),
  &:last-child {
    border-right: none;
  }
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7),
  &:last-child {
    border-bottom: none;
  }
  .icon:hover {
    fill: #fff;
    color: #fff;
  }

  .technology-card-title {
    margin-top: 30px;
    text-align: center;
  }
  .technology-card-description {
    color: var(--dClick-color-primary-blue);
    text-align: center;
    font-weight: 400;
    font-size: var(--dClick-font-size-small);
    margin-top: 16px;
    padding-left: 10px;
    width: 100%;
    transition: all 0.5s ease;
  }
  &:hover {
    background-color: var(--dClick-color-primary-third-blue);
    padding-top: 30px;
    .technology-card-description {
      color: var(--dClick-white);
      padding-left: 0px;
    }
    path {
      fill: var(--dClick-white);
    }
  }
}
@media only screen and (max-width: 1250px) {
  .technology-card {
    border: none;
  }
}