.privacy-policy {
  .privacy-policy-title {
    text-align: center;
    font-weight: 500;
    color: var(--dClick-white);
    font-size: var(--dClick-font-size-x-large);
  }
  .privacy-policy-body {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    a {
      word-wrap: break-word;
    }
  }
}
@media only screen and (max-width: 768px) {
  .privacy-policy {
    .privacy-policy-body {
      font-size: var(--dClick-font-size-x-small);
    }
  }
}
