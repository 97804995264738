.home {
  display: flex;
  flex-direction: column;

  .home-header-arrow {
    position: absolute;
    bottom: 0px;
    left: -15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .arrow-line {
      border-left: 1px solid var(--dClick-color-primary-blue);
      height: 200px;
    }
    .mouse {
      width: 15px;
      height: 25px;
      border: 2px solid black;
      border-top: none;
      border-radius: 30px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      position: relative;
      margin-top: 2px;
      &::before {
        content: "";
        width: 2px;
        height: 6px;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        opacity: 1;
        animation: wheel 2s infinite;
      }
    }
    .scroll {
      opacity: 0;
      display: block;
      width: 18px;
      height: 18px;
      border-left: 2px solid black;
      border-bottom: 2px solid black;
      transform: rotate(-45deg);
      animation: down 2s infinite;
    }
  }
  .home-header-dots {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: absolute;
    bottom: 0px;
    right: -15px;

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #ebebec;
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .dot-1 {
      animation: dot-spin1 15s infinite;
    }
    .dot-2 {
      animation: dot-spin2 15s infinite;
    }
    .dot-3 {
      animation: dot-spin3 15s infinite;
    }
    .dot-4 {
      animation: dot-spin4 15s infinite;
    }
  }
  .home-header {
    font-weight: 500;
    text-align: center;
    color: var(--dClick-white);
    font-size: var(--dClick-font-size-x-large);
    margin-top: 300px;

    .home-header-title-container {
      .home-header-title {
        height: 200px;
        font-weight: 500;
        font-size: var(--dClick-font-size-x-large);
        opacity: 0;
        position: absolute;
        top: 50px;
        width: 100%;
      }
      .t-1 {
        animation: spin1 15s infinite;
      }
      .t-2 {
        animation: spin2 15s infinite;
      }
      .t-3 {
        animation: spin3 15s infinite;
      }
      .t-4 {
        animation: spin4 15s infinite;
      }
    }

    .home-header-button {
      display: flex;
      justify-content: center;
      .button-text {
        color: var(--dClick-white);
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100px;
      }
      .s-1 {
        /*padding-right: 65px;*/
        animation: buttonSpan2 3s infinite;
      }
      .s-2 {
        /*padding-left: 65px;*/
        animation: buttonSpan1 3s infinite;
      }
    }
  }

  .home-body {
    z-index: 4;
    background-color: var(--dClick-background-color);
    width: 100%;
    padding-bottom: 60px;
    .home-body-card {
      width: 82%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      margin-top: -125px;
      margin-bottom: 60px;
    }
    .home-services {
      width: 82%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @keyframes down {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
  }
  @keyframes wheel {
    to {
      opacity: 0;
      top: 20px;
    }
  }
  @keyframes buttonSpan1 {
    0% {
      opacity: 0;
    }
    50% {
      /*padding-left: 0;*/
      opacity: 1;
    }
  }
  @keyframes buttonSpan2 {
    50% {
      opacity: 0;
    }
    100% {
      /*padding-right: 0;*/
      opacity: 1;
    }
  }

  @keyframes spin1 {
    0% {
      opacity: 0;
    }

    12.5% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
  }
  @keyframes spin2 {
    25% {
      opacity: 0;
    }

    37.5% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  @keyframes spin3 {
    50% {
      opacity: 0;
    }

    62.5% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
  }
  @keyframes spin4 {
    75% {
      opacity: 0;
    }

    87.5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes dot-spin1 {
    0% {
      opacity: 0.5;
    }

    12.5% {
      opacity: 1;
    }
    25% {
      opacity: 0.5;
    }
  }
  @keyframes dot-spin2 {
    25% {
      opacity: 0.5;
    }

    37.5% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  @keyframes dot-spin3 {
    50% {
      opacity: 0.5;
    }

    62.5% {
      opacity: 1;
    }
    75% {
      opacity: 0.5;
    }
  }
  @keyframes dot-spin4 {
    75% {
      opacity: 0.5;
    }

    87.5% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .home {
    .home-header-arrow {
      .arrow-line {
        height: 125px;
      }
      .mouse {
        width: 9px;
        height: 20px;
      }
      .scroll {
        width: 10px;
        height: 10px;
      }
    }
    .home-header {
      margin-top: 200px;
      .home-header-title-container {
        .home-header-title {
          font-size: var(--dClick-font-size-small-medium);
        }
      }
    }
    .home-body {
      .home-body-card {
        width: 90%;
        margin-bottom: 40px;
      }
      .home-services {
        width: 90%;
        flex-direction: column;
      }
    }
  }
  @keyframes wheel {
    to {
      opacity: 0;
      top: 10px;
    }
  }
}
