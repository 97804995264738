.fade-in {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
  &.is-visible{
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
