.technologies-section {
  margin-top: 30px;
  color: var(--dClick-white);
  background-color: var(--dClick-color-primary-blue);
  padding-top: 60px;
  height: 900px;
  .technologies-section-container {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    font-size: var(--dClick-font-size-large);
    font-weight: 700;
    .technologies-list {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
@media only screen and (max-width: 1250px) {
  .technologies-section{
    height: unset;
    .technologies-section-container {
      width: 90%;
      font-size: var(--dClick-font-size-small-medium);
      .technologies-list {
        display: flex;
        flex-direction: column;
      }
    }
  }
}