.services-card {
  width: 100%;
  min-height: 260px;
  padding: 20px;
  background-color: var(--dClick-white);
  font-weight: 500;
  position: relative;
  transition: all 0.5s;
  hr {
    border: 1px solid var(--dClick-color-primary-third-blue);
    transition: all 0.5s;
  }
  .card-title {
    font-size: var(--dClick-font-size-small-medium);
    font-weight: 500;
    transition: all 0.5s;
  }
  .cart-description {
    color: var(--dClick-sub-text-color);
    font-size: var(--dClick-font-size-small);
    transition: all 0.5s;
  }
  .services-card-link {
    a {
      text-decoration: none;
      color: var(--dClick-color-primary-third-blue);
      font-size: var(--dClick-font-size-small);
      display: flex;
      align-items: center;
      div.right-arrow {
        margin-left: 5px;
        .arrow {
          background-color: var(--dClick-color-primary-third-blue);
        }
      }
    }

    height: 24px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 40px;
    transition: all 0.5s;
    span {
      position: relative;
      top: 10%;
      font-size: var(--dClick-font-size-small-medium);
    }
  }
  &:hover {
    background-color: var(--dClick-color-red-normal);

    hr {
      border: 1px solid var(--dClick-white);
    }
    .cart-description,
    .services-card-link,
    .card-title {
      color: var(--dClick-white);
      a {
        color: var(--dClick-white);
        div.right-arrow {
          .arrow {
            background-color: var(--dClick-white);
          }
        }
      }
    }
    .services-card-link {
      right: 20px;
    }
    path {
      fill: var(--dClick-white);
    }
  }
}
@media only screen and (max-width: 1250px) {
  .services-card {
    min-height: 240px;
    width: unset;
    .card-title {
      font-size: var(--dClick-font-size-small);
    }
    .cart-description {
      font-size: var(--dClick-font-size-x-small);
    }
  }
}
