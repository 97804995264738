.our-mission-section {
  height: 150vh;
  position: relative;
  margin-top: 18px;
  display: flex;
  h2 {
    font-weight: 700;
    font-size: var(--dClick-font-size-medium);
  }
  p {
    font-weight: 400;
    font-size: var(--dClick-font-size-small);
  }
  .left-side-images {
    display: flex;
    flex-direction: column;
    .image-1 {
      width: 21vw;
    }
    .image-2 {
      object-fit: cover;
      margin-top: 19px;
      width: 19.8vw;
    }

    .bottom-images {
      display: flex;
      gap: 20px;
    }
  }
  .right-side-images {
    .image-4 {
      margin-top: 65px;
      margin-left: 20px;
      margin-right: 21px;
      margin-bottom: 57px;
      width: 19.8vw;
    }
    .image-5 {
      position: absolute;
      top: 37%;
      right: 45vw;
      width: 25vw;
    }
  }
  .our-mission {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 20px;

    .image-7 {
      width: 19.8vw;
      margin-top: 60px;
    }
    .our-mission-left {
      display: grid;
      width: 33.8vw;
      .image-6 {
        width: 19.8vw;
        justify-self: end;
      }
    }
  }

  .image-8 {
    position: absolute;
    bottom: 50px;
    right: 78px;
  }
  .our-vision {
    position: absolute;
    bottom: 30px;
    left: 18px;
    display: flex;
    gap: 20px;
    width: 100%;
    .image-3 {
      width: 19.8vw;
      align-self: flex-end;
    }
    .image-8 {
      width: 33.75vw;
      margin-left: 63px;
    }
    .our-vision-image-container {
      width: 33.8vw;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .our-mission-section {
    height: 720px;
    display: block;
    h2 {
      font-size: var(--dClick-font-size-small);
      margin: 0;
    }
    p {
      font-size: var(--dClick-font-size-x-small);
    }
    .left-side-images {
      .image-1 {
        width: 32%;
        margin-left: 0px;
        height: calc(730px * 20 / 100);
        object-fit: cover;
      }
      .image-2 {
        margin-top: 5px;
        width: 32%;
        height: calc(730px * 12 / 100);
        object-fit: cover;
      }

      .bottom-images {
        display: flex;
        gap: 20px;
      }
    }

    .right-side-images {
      .image-4 {
        display: none;
      }
      .image-5 {
        display: none;
      }
    }
    .our-mission {
      position: static;
      .image-7 {
        display: none;
      }
      .our-mission-left {
        display: unset;
        position: absolute;
        top: 0px;
        right: 20px;
        width: 60%;
        .image-6 {
          width: 39vw;
          height: calc(730px * 17 / 100);
          object-fit: cover;
          position: absolute;
          right: -20px;
          top: 580px;
          margin-bottom: unset;
        }
      }
    }
    .our-vision {
      bottom: unset;
      width: unset;
      margin-top: 14px;
      .image-3 {
        display: none;
      }
      .image-8 {
        display: none;
      }
      .our-vision-image-container {
        width: 86%;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 1250px) {
  .our-mission-section {
    height: 430px;
    .our-mission {
      .our-mission-left {
        .image-6 {
          top: 150px;
        }
      }
    }
  }
}
