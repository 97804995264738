.card {
  border: 1px solid #ebebec;
  box-shadow: 0px 4px 4px rgba(233, 236, 252, 0.6);
  border-radius: 8px;
  padding: 24px;
  background-color: var(--dClick-white);
  .card-title {
    font-size: var(--dClick-font-size-small-medium);
    font-weight: 500;
    margin-top: 25px;
  }
  .card-description {
    font-size: var(--dClick-font-size-small);
    color: var(--dClick-sub-text-color);
    margin-top: 16px;
  }
  .button{
    margin-top: 16px;
  }
}
