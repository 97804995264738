@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.font-Crimson{
  font-family: "Crimson Pro", serif;
}
:root {
  --dClick-font-size-x-x-small:10px;
  --dClick-font-size-x-small: 13px;
  --dClick-font-size-small: 16px;
  --dClick-font-size-small-medium: 20px;
  --dClick-font-size-medium: 25px;
  --dClick-font-size-large: 31px;
  --dClick-font-size-x-large: 39px;


  --dClick-white: white;
  --dClick-sub-text-color: #5a5d61;
  --dClick-borders-text-color: #ebebec;
  --dClick-placeholder-text-color: #c1c2c3;
  --dClick-background-color: #edf1f4;
  --dClick-color-red-normal: #ed4622;
  --dClick-color-red-normal-hover: #d53f1f;
  --dClick-color-red-secondary-light: #f9c6ba;
  --dClick-color-primary-blue: #121e52;
  --dClick-color-primary-dark-blue: #0e1842;
  --dClick-color-primary-third-blue: #0e33ca;
}
