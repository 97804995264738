.footer {
  background-color: var(--dClick-color-primary-dark-blue);
  color: var(--dClick-white);
  padding-bottom: 60px;
  .footer-container {
    width: 80%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 145px;
    color: var(--dClick-white);
    .footer-header {
      align-self: center;
      flex: 1;
      h2 {
        font-weight: 500;
        font-size: var(--dClick-font-size-x-large);
      }
    }
    .footer-body {
      display: flex;
      gap: 32px;
      align-self: flex-end;
      justify-content: flex-end;
      flex: 1;
      .footer-links-container {
        font-weight: 500;
        font-size: var(--dClick-font-size-small-medium);
        display: flex;
        flex-direction: column;
        gap: 16px;
        .footer-link {
          font-weight: 400;
          font-size: var(--dClick-font-size-x-small);
          color: var(--dClick-placeholder-text-color);
          a {
            color: var(--dClick-placeholder-text-color);
            text-decoration: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1250px) {
  .footer {
    height: unset;
    .footer-container {
      width: 90%;
      flex-direction: column;
      gap: 24px;
      .footer-header {
        align-self: flex-start;
        img {
          width: 100px;
        }
        h2 {
          font-size: var(--dClick-font-size-small);
        }
      }
      .footer-body {
        flex-direction: column;
        align-self: flex-start;
        margin-bottom: 50px;
        .footer-links-container {
          font-size: var(--dClick-font-size-small-medium);

          .footer-link {
            font-size: var(--dClick-font-size-x-small);
          }
        }
      }
    }
  }
}
