.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 7px;
  position: absolute;

  top: 0px;
  color: var(--dClick-white);
  font-weight: 400;
  z-index: 5;
  width: 99%;
  .navigation-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    div {
      cursor: pointer;
      height: 25px;
    }
    a:after {
      content: "";
      display: block;
      margin: auto;
      height: 1px;
      width: 0px;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease;
    }
    a.active:after {
      width: 100%;
      background-color: var(--dClick-white);
    }
    a:not(:last-child):hover:after {
      width: 100%;
      background-color: var(--dClick-sub-text-color);
    }
  }
  .hamburger {
    display: none;
    .bar {
      display: block;
      width: 25px;
      height: 3px;
      margin: 5px auto;
      border-radius: 5%;
      transition: all 0.3s ease-in-out;
      background-color: var(--dClick-placeholder-text-color);
      border-radius: 5px;
    }
  }
  .logo {
    display: flex;
    height: 100%;

    img {
      width: 100px;
    }
  }
  a {
    color: var(--dClick-white);
    text-decoration: none;
  }

  .contact {
    text-decoration: none;
    color: var(--dClick-white);
    border: 1px solid var(--dClick-white);
    border-radius: 16px;
    padding: 8px 16px;
    transition: all 500ms;
  }
  .contact:hover {
    color: var(--dClick-color-red-normal);
    background-color: var(--dClick-color-red-secondary-light);
    border-color: var(--dClick-color-red-secondary-light);
  }
}
@media only screen and (max-width: 1250px) {
  .navigation {
    width: 97%;
    position: fixed;
    .logo {
      margin-left: 10px;
      img {
        width: 41px;
      }
    }
    .navigation-links {
      position: absolute;
      left: -107%;
      top: 5rem;
      flex-direction: column;
      width: 100%;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
      background: linear-gradient(
        180deg,
        rgba(237, 70, 34, 0.5) 0%,
        #ed4622 0%,
        #f5856d 0%,
        #ed4622 41.4%,
        #ed4622 73.06%,
        #ed4622 107.15%
      );
      padding-top: 10px;
      padding-bottom: 10px;
      z-index: 5;
    }
    .navigation-links.active {
      left: 0;
    }
    .hamburger {
      display: block;
      margin-right: 10px;
    }
    .hamburger.active .bar:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
